<template>
  <div class="payments__form">
    <header class="payments__header">
      <div class="payments__search">
        <SearchForm @searchEvent="searchHandler" />
      </div>
      <div class="payments__date"><DateForm /></div>
    </header>
    <div class="payments__month">
      Month: {{ $moment(this.now).format('MMMM') }}
    </div>
    <div class="filters">
      <vs-checkbox v-model="showExcluded">Show exluded students</vs-checkbox>
      <vs-checkbox v-model="showDebtor">Показать долги</vs-checkbox>
      <vs-input
        v-model="minCredit"
        v-if="showDebtor"
        label="Не показывать суммы меньше"
      ></vs-input>
      <vs-checkbox v-model="showOverpay">Показать переплаты</vs-checkbox>
      <vs-input
        v-model="minOverPay"
        v-if="showOverpay"
        label="Не показывать суммы меньше"
      ></vs-input>
    </div>
    <vs-progress :indeterminate="saving" color="primary"></vs-progress>
    <div><PaymentForm :search="search" :data="filteredData" /></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchForm from '@/components/payments/commonForm/search'
import DateForm from '@/components/payments/commonForm/date'
import PaymentForm from '@/components/payments/commonForm/form'
// import getOrdersStudents from "@/api/orders/getOrdersStudents";

export default {
  name: 'commonPaymentFormWrapper',
  props: {
    type: {
      type: String,
      default: 'group',
    },
  },
  data() {
    return {
      search: '',
      orders: [],
      dataWithOrders: [],
      now: new Date(),
      saving: true,
      showExcluded: false,
      showDebtor: false,
      showOverpay: false,
      computedOrders: {},
      minCredit: 50,
      minOverPay: 50,
    }
  },
  computed: {
    ...mapState({
      groups: state => state.groups.groups,
      currentSchoolYear: state => state.years.currentSchoolYear,
      balances: state => state.students.balances,
      ordersAll: state => state.orders.ordersAll,
    }),
    filteredData: function() {
      let groups_data = [],
        tutor_data = []
      if (typeof this.ordersAll.groups != 'undefined') {
        groups_data = this.ordersAll.groups.map(g => g)
      }
      if (typeof this.ordersAll.tutor != 'undefined') {
        tutor_data = this.ordersAll.tutor.map(t => t)
      }
      let groups = groups_data.reduce((list, group) => {
        group = { ...group }
        let students = group.students.reduce((list, student) => {
          const balance = student.balance.diff_fact
          const balance_plan = student.balance.diff_plan

          if (!student.name.toLowerCase().includes(this.search.toLowerCase())) {
            return list
          }
          if (this.showDebtor) {
            if (
              (balance < 0 && -this.minCredit > balance) ||
              (balance_plan < 0 && -this.minCredit > balance_plan)
            ) {
              list.push(student)
              // оставляем только учеников с отрицательным балансом
              return list
            } else {
              // выходим
              return list
            }
          }
          if (this.showOverpay) {
            if (
              (balance > 0 && this.minOverPay < balance) ||
              (balance_plan > 0 && this.minOverPay < balance_plan)
            ) {
              list.push(student)
              // оставляем только учеников с отрицательным балансом
              return list
            } else {
              // выходим
              return list
            }
          }

          if (student.is_active) list.push(student)
          else {
            if (this.showExcluded) list.push(student)
          }
          return list
        }, [])
        group.students = students
        list.push(group)
        if (!students.length) {
          group.hidden = true
          return list
        }
        return list
      }, [])

      let tutor = tutor_data.reduce((list, student) => {
        if (!student.name.toLowerCase().includes(this.search.toLowerCase())) {
          return list
        }
        const balance = student.balance.diff_fact
        const balance_plan = student.balance.diff_plan

        if (this.showDebtor) {
          if (
            (balance < 0 && -this.minCredit > balance) ||
            (balance_plan < 0 && -this.minCredit > balance_plan)
          ) {
            list.push(student)
            // оставляем только учеников с отрицательным балансом
            return list
          } else {
            // выходим
            return list
          }
        }
        if (this.showOverpay) {
          if (
            (balance > 0 && this.minOverPay < balance) ||
            (balance_plan > 0 && this.minOverPay < balance_plan)
          ) {
            list.push(student)
            // оставляем только учеников с отрицательным балансом
            return list
          } else {
            // выходим
            return list
          }
        }
        list.push(student)
        return list
      }, [])
      return { groups, tutor }
    },
  },
  components: { SearchForm, DateForm, PaymentForm },
  created: async function() {
    this.$store.dispatch('payments/getSourcesAction')
    if (!this.currentSchoolYear.id) {
      await this.$store.dispatch('years/getYearsAction')
    }
    const schoolyear_id = this.currentSchoolYear.id
    const schoolyearDateStart = new Date(
      this.currentSchoolYear.date_start_unix * 1000
    )
    this.now = this.now < schoolyearDateStart ? schoolyearDateStart : this.now

    const month = this.now.getMonth() + 1
    const year = this.now.getFullYear()
    const { type } = this

    this.$store
      .dispatch('orders/getAllOrdersAction', {
        schoolyear_id,
        month,
        year,
        type,
      })
      .then(() => (this.saving = false))
  },
  methods: {
    searchHandler: function(data) {
      this.search = data
    },
    setOrders: function() {},
  },
}
</script>

<style lang="scss">
.payments__header {
  display: flex;
  flex-flow: row nowrap;
  position: sticky;
  top: 55px;
  padding: 15px 0;
  z-index: 10;
  .payments__search {
    width: 15%;
    margin-right: 45px;
    padding: 10px 0;
  }
  .payments__date {
    padding: 10px 15px;
    background-color: #f1f1f1;
    border-radius: 10px;
  }
  .payments__date {
    padding: 10px 15px;
    background-color: #f1f1f1;
    border-radius: 10px;
  }
  .payment__book-form-add-button {
    padding: 1rem;
    margin: 0 2rem;
  }
}
.payments__month {
  font-weight: bold;
  font-size: 1.1em;
}
.filters {
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
}
</style>
